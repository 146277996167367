@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap");
.about_section_container {

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.about_long_descrp p{
  padding-bottom: 10px;
}
.about_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
.about_infos {
  padding: 0px 10px 20px 25px;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.about_short_descrp {
  font-size: 27px;
  text-align: left;
  font-weight: 500;
}
.about_long_descrp {
  text-align: justify;
  margin: 10px 10px 0px 0px;
}

.about_align_btn {
  display: flex;
  width: 99%;
  justify-content: center;
  align-content: center;
  margin: 10px;
}

.more_info_btn {
  width: 174px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid rgb(4, 170, 176);
  font-size: 15px;
  color: #fff;
  background-color: rgb(4, 170, 176);
  margin-top: 19px;
  flex-direction: column;
  align-items: center;
  transition: ease-in-out 0.3s;
  box-shadow: inset 0 0 0 0 rgb(1, 168, 171);
  font-weight: 500;
  border-radius: 5px;
}
.more_info_btn:hover {
  color: rgb(255, 255, 255);
  border: 2px solid #fff;
  box-shadow: inset 174px 0 0 0 rgb(2, 218, 222);
}
.more_info_btn:active {
  transform: scale(0.98);
  color: rgb(255, 255, 255);
  border: 2px solid #fff;
  box-shadow: inset 100% 0 0 0 rgb(1, 168, 171);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
.about_title {
  width: 80%;
}
.about_title .about_title_logo {
  margin-right: 9px !important;
}

.about_image img {
  width: 403px;
  /* outline: 16px solid #c6c6c6; */
  border-radius: 9px;
  transition: ease-in-out 0.3s;
}
.about_image img:hover {
  transform: scale(1.25);
}
@media screen and (min-width: 1024px) {
  .about_title {
    position: relative;
    top: 76px;
    width: 85%;
  }
  .about_container {
    width: 90%;
    height: 550px;
    
  }
  .about_infos {
    width: 651px;
    height: 374px;
  }
  .about_image img {
    width: 462px;
    height: 311px;
  }
}
@media screen and (max-width: 1023px) {
  .about_container {
    height: 90vh;
    width: 90%;
  }
  .about_infos {
    padding: 0px 56px 20px 25px;
  }
  .about_title {
    width: 90%;
    margin-top: 50px;
    margin-bottom: -39px;
  }
}

@media screen and (max-width: 940px) {
  .about_container {
    height: 100vh;
  }
  .about_container {
    flex-direction: column;
  }
  .about_title {
    width: 90%;
  }
  .about_infos {
    margin-bottom: 61px;
    margin-top: 47px;
  }
}
@media screen and (max-width: 720px) {
  .about_container {
    flex-direction: column;
    height: 800px;
  }
}
@media screen and (max-width: 580px) {
  /* .about_container {
    margin-top: 46px;
  } */
  .about_infos {
    margin-top: 20px;
  }
  .about_image {
    margin-top: 20px;
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 480px) {
  .about_title {
    font-size: 27px;
    margin: 53px 0px 0px 0px !important;
  }
  .about_infos {
    margin-bottom: 20px;
  }
  .about_image {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .about_image img {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .about_infos {
    padding: 0px 20px 20px 25px;
  }
  /* .about_container {
    margin-top: 0;
  } */
  .more_info_btn {
    background: transparent;
    color: #000;
    outline: 1px solid #000;
    border: none;
    border-radius: 6px;
  }
}

/* @media screen and (max-width: 480px) {
  .about_container {
    height: 100%;
  }
} */

/* @media screen and (min-width: 1024px) and (max-width: 1200px) {
  
  .about_section_container {
    padding-bottom: 140px;
  }
}
@media screen and (min-width: 480px) and (max-width: 940px) {
  
  .about_section_container {
    padding-bottom: 160px;
  }
}
@media screen and (min-width: 720px) and (max-width: 780px) {
  
  .about_section_container {
    padding-bottom: 260px;
  }
}
@media screen and (min-width: 481px) and (max-width: 670px) {
  
  .about_section_container {
    padding-bottom: 260px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1053px) {
  
  .about_section_container {
    padding-bottom: 260px;
  }
}
@media screen and (min-width: 924px) and (max-width: 1024px) {
  .about_infos{
    padding-top:170px;
  }
  
} */ 
.cointainer_about{
  display: flex;
  flex-wrap: wrap;
}
.about_us_main{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2% 0;
}
.about_para_details_img{
  display: flex;
  flex-wrap: wrap;
}
.about_points{
  width: 59%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .about_points p{
  width: 80%;
  margin-left: 20%;
} */
.about_img_in{
  
  width:40%;
  display: flex;
  
  justify-content: center;
  align-items: center;
}
.about_img_in img{
   width:80%;
   border-radius: 9px;
  transition: ease-in-out 0.3s;
}
.about_img_in img:hover{
  transform: scale(1.25);
}
.about_us_con{
  margin-left:5.2%;
}
.about_short_descrp_main {
  font-size: 27px;
  text-align: left;
  font-weight: 500;
 
  padding-bottom: 15px;
}
.about_long_descrp_main p{
  padding-bottom: 15px;
}
.about_long_descrp_main {
  text-align: justify;
 
}
.about_long_descrp_main,.about_short_descrp_main{
  margin: 0 20%;
}
@media screen and (max-width: 924px){
  .about_img_in{
    width:100%;
    height: auto;
  }
  .about_points{
    width:100%;
  }
  .about_img_in img{
    width:50%;
    height: auto;
    border-radius: 9px;
   transition: ease-in-out 0.3s;
 }
 .about_img_in img:hover{
   transform: scale(1.25);
 }
 .about_long_descrp_main,.about_short_descrp_main{
  margin: 0 10%;
}
}