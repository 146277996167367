.profile_section_container {
  margin: 2rem;
}

.profile_details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
  padding: 15px 15px;
  backdrop-filter: blur(6px);
  background-color: #ffffff4a;
  border-radius: 6px;
  /* border: 1px solid black; */
  margin: 1rem;
}
.profile_details img {
  border-radius: 50%;
  width: 130px;
  height: 50%;
  border: 2px solid rgb(4, 170, 176);
  box-shadow: 0 0 6px 1px #c2c0c0ba;
}
.profile_info {
  text-align: center;
  height: 50%;
}
.profile_name {
  font-size: 18px;
  height: 50%;
  padding: 15px 0;
}
.profile_GDC {
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 600;
}
.profile_occupation {
  height: 50%;
  padding: 15px 0;
}
.profile_occupation,
.profile_name {
  color: #232323;
  font-weight: 500;
}
.profile_description {
  width: 86%;
  text-align: justify;
}
.p_info_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  /* margin: 2rem; */
}

.profile_section_container h2 span {
  margin-right: 9px;
}

/* @media screen and (min-width: 1024px) {
  .profile_section_container {
    height: 80vh;
  }
} */

@media screen and (max-width: 940px) {
  .profile_section_container h2 {
    width: 90%;
  }
}

@media screen and (max-width: 720px) {
  .p_info_container {
    width: 90%;
  }
}
@media screen and (max-width: 580px) {
  .profile_section_container {
    gap: 0;
    height: auto;
  }
  .p_info_container {
    flex-direction: column;
    margin-top: 39px;
    margin-bottom: 30px;
  }
  .profile_section_container h2 {
    margin-top: 41px;
  }
}

@media screen and (max-width: 480px) {
  .profile_details {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .profile_details {
    width: 100%;
  }
}
