.nav-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px 0px #c8c8c8;
  position: sticky;
  top: 0;
  z-index: 1020;
  height: 72px;
  background: #fff;
  width: 100%;
}

.logo {
  display: flex;
  min-width: 250px;
  justify-content: space-evenly;
  align-items: center;
}

.logo img {
  width: 60px;
  height: 60px;
  margin: 0 10px;
}
.logo h3 {
  padding: 5px 5px;
}
.logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.links {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 10px;
  align-content: center;
  justify-content: center;
}
.MenuItems {
  margin: 10px 10px;
  font-size: 18px;
  line-height: 45px;
  text-align: left;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.bgMenu {
  background: #50c41f;
  padding: 0px 9px;
  border-radius: 6px;
}

#Appointment_menu a {
  color: #fff;
  text-align: center;
}
.MenuItems a {
  text-decoration: none;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: black;
  cursor: pointer;
}
.MenuItems a:active {
  transform: scale(0.97);
  transition: ease-in-out 0.4;
}
.MenuItems::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
}

.active_links {
  display: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.toggle_menu_icons {
  display: none;
}
@media screen and (min-width: 1024px) {
  .MenuItems {
    font-size: 17px;
  }
}
@media screen and (max-width: 950px) {
  .links {
    display: none;
  }
  .active_links {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 72px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: #f5f5f5;
    z-index: 71;
    transition-delay: 0.6s;
    transition: all 300ms ease-in;
    padding: 47px 20px;
  }
  .active_links::before {
    content: "";
    width: 100%;
    height: 4px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 6s ease infinite;
    position: absolute;
    top: -3px;
    transition: all 400ms ease-in;
  }
  .MenuItems a {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    color: black;
    text-align: center;
    border-radius: 10px;
    /* width: 100%; */
    background: transparent;
  }
  .MenuItems a:hover {
    text-align: center;
  }
  .toggle_menu_icons {
    display: flex;
    height: 50px;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  #Appointment_menu {
    height: 45px;
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 #f43e04;
    transition: ease-in-out 0.3s;
    width: 25%;
  }
  #Appointment_menu a {
    color: #fff;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
}
